import '../styles/Home.css';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { leagacyIcons as icons } from '../common/icons';
import { icons as newIcons } from '../common/IconsFunc';

import banner_1 from '../images/banner_1.png';

import news_logo_1 from '../images/news_logo/logo.png';

import news_title from '../images/home_list/main.jpg';

import home_i_1 from '../images/home_list/1.jpg'
import home_i_2 from '../images/home_list/2.jpg'
import home_i_3 from '../images/home_list/3.jpg'

// import adv6 from '../images/adv/adv_ai_1.jpg';
import adv6 from '../images/adv/adv2.png';

function Home () {
  const [loginPage, setLoginPage] = useState(0);
  const [searchParam, setSearchParams] = useSearchParams();

  const loginOn = () => {
    // todo leejgyu => login id
    setSearchParams(`login=leejgyu`)
  }

  useEffect(() => {
    if(searchParam.get('login')) {
      setLoginPage(1);
    } else {
      setLoginPage(0);
    }
  }, [searchParam]);

  return (
    <div className='Home'>
      <div className='w1200 dFlex fCenter' id='Home'>

        <div className='homeBox dFlex' id='home_1'>
          <div className='homeLeft' id='home_1_banner'>
            <img src={banner_1} alt='banner_1' />
          </div>

          <div className='blankFlex'></div>

          <div className='homeRight' id='login_box'>
            {
              !loginPage ?
              <>
                <div id='login_box_title'>LEGOO를 더 안전하고 편리하게 이용하세요</div>

                <div id='login_box_btn' onClick={loginOn}>
                  <span className='fBold mr10'>LEGOO</span>
                  로그인
                </div>

                <div id='login_box_footer'>
                  <div className='login_box_footer_hover'>아이디</div>
                  <div>ㆍ</div>
                  <div className='login_box_footer_hover'>비밀번호찾기</div>
                  <div className='blankFlex'></div>
                  <div className='login_box_footer_hover'>회원가입</div>
                </div>
              </>
              :
              <div className='user'>
                <div className='top'>
                  <div className='img'>
                    <newIcons.Header8 />
                  </div>

                  <div className='info'>
                    <div className='name'>
                      <span className='high'>이정규</span>
                      <span className='fBlank'>님</span>
                      <span className='logout'>로그아웃</span>
                    </div>
                    <div className='id'>2regular@legoo.site</div>
                  </div>
                </div>

                <div className='bot'>
                  <div className='btns' onClick={() => window.location = 'https://n-mail.vercel.app/'}>메일</div>
                  <div className='btns'>카페</div>
                  <div className='btns'>블로그</div>
                  <div className='btns end'>페이</div>
                </div>
              </div>
            }
          </div>
        </div>

        <div className='homeBox dFlex' id='home_2'>
          <div className='homeLeft' id='home_2_left'>
            <div className='dFlex fCenter' id='home_2_left_left'>
              <div className='fBold mr10'>한산일보</div>
              <div className='mr10'>{">"}</div>
              <div id='home_2_left_left_title'>서은 총재 "집값 고점보다 30% 이상 떨어지면 금융ㆍPF 어려움"</div>
            </div>

            <div className='blankFlex'></div>

            <div className='dFlex fCenter' id='home_2_left_right'>
              <div className='fBold fHigh2'>뉴스홈</div>
              <div id='home_2_left_dot'>ㆍ</div>
              <div className='f15 fBold mr10'>연예</div>
              <div className='f15 fBold mr10'>스포츠</div>
              <div className='f15 fBold'>경제</div>
            </div>
          </div>

          <div className='blankFlex'></div>

          <div className='dFlex homeRight f15' id='home_2_right'>
            <div className='fBold mr10' id='home_2_right_f'>이슈</div>
            <div className='mr10' id='home_2_right_middle'>|</div>
            <div>유성그룹 낙찰경쟁 삼파전 돌입</div>
          </div>
        </div>

        <div className='homeBox dFlex' id='home_3'>
          <div className='homeLeft' id='home_3_left'>
            <div id='news_header'>
              <div className='mr10 news_icons'>
                <img src={icons.news} alt='news_icons' />
              </div>
              <div className='fBold mr10'>뉴스스탠드</div>
              <div className='mr10'>{">"}</div>
              <div className='mr5 fGray'>구독한 언론사</div>
              <div className='mr5 fGray'>ㆍ</div>
              <div className='fBold'>전체 언론사</div>

              <div className='blankFlex'></div>

              <div className='mr10 news_icons'>
                <img src={icons.news_list} alt='news_icons' />
              </div>
              <div className='mr10 news_icons'>
                <img src={icons.news_table} alt='news_icons' />
              </div>
              <div className='news_icons'>
                <img src={icons.news_gear} alt='news_icons' />
              </div>
            </div>

            <div id='news_main'>
              <div id='news_left'>
                <div className='news_left_list news_left_list_high'>종합</div>
                <div className='news_left_list'>방송/통신</div>
                <div className='news_left_list'>경제/IT</div>
                <div className='news_left_list'>스포츠/연예</div>
                <div className='news_left_list'>매거진</div>
                <div className='news_left_list'>전문지</div>
                <div className='news_left_list'>지역</div>
              </div>

              <div id='news_right'>
                <div id='news_right_header'>
                  <img id='news_right_logo' src={news_logo_1} alt='news_logo' />
                  <div className='mr10'>한산일보</div>
                  <div id='news_right_header_btn'>
                    <div className='mr5 fHigh fBold'>+</div>
                    <div>구독하기</div>
                  </div>
                </div>

                {/* <div id='news_right_main'>
                  <div id='news_right_main_left'>
                    <div id='news_title_img'>
                      <img src={news_title} alt='hi' />
                    </div>

                    <div id='news_title_text'>양자컴퓨터 클라우드 서비스 개시… 8월 수도권 하늘에 뜬다</div>
                  </div>

                  <div id='news_right_main_right'>
                    <div>한국당-공화당, 중도층서 23.5%P 격차</div>
                    <div>[프레지던트] 방미 전 생중계로 ‘민생 챙기기’</div>
                    <div>창주로보틱스, 기능 고도화시킨 ‘자율주행 물류 로봇’</div>
                    <div>‘실적 추락’ 인베스트 KCH 2세 유근수 ‘한숨’</div>
                    <div>[김찬욱 지지율] 20~50대서 20%대 지지율</div>
                    <div>주택청약 가입자 수 9개월 연속 감소 이유는?</div>
                    <div>[그래픽뉴스] ‘위스키’, 부자들은 먹지 않고 투자한다</div>
                    <div>나트륨 이온 배터리 성능 극대화 해법 찾아…리튬 대</div>
                  </div>

                  <div id='news_arrow_box'>
                    <div id='news_arrow_btn'>{">"}</div>
                  </div>
                </div> */}

                <div id='news_right_main'>
                  <div id='news_sockbo'>
                    <div onClick={() => window.location = "https://legoo-news.vercel.app/article/01/00381201654"} className='top'>
                      <div className='img_box'>
                        <img src='/images/sockbo.png' alt='sockbo' />
                      </div>

                      <div className='t_box'>
                        <div className='title'>{`[속보] 광호동 흉기 난동 발생`}</div>
                        <div>7일 오후 2시에 서울 광호동에서 30대 남성이 차량에서 내려 시민들에게 흉기를 휘둘렀다는 112 신고가 접수됐다.</div>
                      </div>
                    </div>

                    <div className='bot'>
                      <div className='t'>서울 광호동 흉기 난동 30대 체포… 2명 부상</div>
                      <div className='t'>광호동 흉기 난동 제압 중… 공포에 질린 시민들</div>
                      <div className='t'>광호동 흉기 난동… 이번엔 예고도 없었다</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className='blankFlex'></div>

          <div className='homeRight' id='home_3_right'>
            <img src={adv6} alt='home_3_adv' />
          </div>
        </div>

        <div className='homeBox dFlex' id='home_4'>
          <div className='homeLeft' id='home_4_left'>
            <div id='home_4_title'>
              <div className='fBold mr10'>오늘 읽을만한 글</div>
              <div className='f14 fGray'>주제별로 분류된 다양한 글 모음</div>
              <div className='blankFlex'></div>
              <div className='f14 fGray'>관심주제 설정</div>
            </div>

            <div id='home_4_btn'>
              <div>자동차</div>
              <div>웹툰</div>
              <div>경제</div>
              <div id='home_4_btn_high'>추천</div>
              <div>레시피</div>
              <div>리빙</div>
              <div>책방</div>
              <div>패션뷰티</div>
            </div>

            <div className='home_4_content'>
              <div className='home_4_content_img'>
                <img src={home_i_3} alt='home_4_content' />
              </div>
              <div className='home_4_content_text'>
                <div className='h4c_class'>추천</div>
                <div className='h4c_title'>“미래의 도시: 새로운 시대의 도래”</div>
                <div className='h4c_body'>해가 지면서 도시를 환하게 비추는 순간, 우리는 단순히 하루가 끝나는 것뿐만 아니라 미래의 경이로운 모습을 엿볼 수 있습니다. 기술과 혁신이 매끄럽게 얽혀 도시 풍경을 변화시키고 이동성을 재정의하는 미래가 펼쳐지고 있습니다. </div>
              </div>
            </div>

            <div className='home_4_content'>
              <div className='home_4_content_img'>
                <img src={home_i_2} alt='home_4_content' />
              </div>
              <div className='home_4_content_text'>
                <div className='h4c_class'>추천</div>
                <div className='h4c_title'>공공클라우드 지난해 24% 성장 … IT·보안업계 '군침'</div>
                <div className='h4c_body'>정부는 올해부터 1만여 개 공공 IT시스템의 70%를 2030년까지 클라우드 네이티브로 전환하는 정책을 추진할 예정이다. 다만 민간 시장을 장악하고 있는 해외 업체인 마존웹서비스(MWS), 이크소프트 저애, 리구 클라우드 등 클라우드 회사(CSP)들이 올해부터</div>
              </div>
            </div>
            
            <div className='home_4_content'>
              <div className='home_4_content_img'>
                <img src={home_i_1} alt='home_4_content' />
              </div>
              <div className='home_4_content_text'>
                <div className='h4c_class'>추천</div>
                <div className='h4c_title'>밤하늘을 수놓는 미래 도시, 서울</div>
                <div className='h4c_body'>미래의 서울은 지금보다 더욱 혁신적이고 지속 가능한 도시가 될 것입니다. 도시는 더 많은 녹지 공간을 갖게 될 것이며, 대중교통 시스템은 더욱 발달할 것입니다. 또한, 서울은 인공지능과 같은 첨단 기술을 적극적으로 활용하여 더욱 효율적이고 편리한 도시가 될 것입니다.</div>
              </div>
            </div>
          </div>

          <div className='blankFlex'></div>

          <div className='homeRight' id='home_4_right'>
            <div>트렌드 쇼핑</div>
          </div>
        </div>

        {/* <div className='homeBox dFlex' id='home_duplicate'>
          <div className='homeLeft' id=''>
          </div>

          <div className='blankFlex'></div>

          <div className='homeRight' id=''>
          </div>
        </div> */}

      </div>
    </div>
  )
}

export default Home;